/* Base styles */
.new-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header styles */
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.main-header.hidden {
  transform: translateY(-100%);
}

.main-header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  max-width: 180px;
}

.logo img {
  width: 100%;
  height: auto;
  transition: transform 0.2s ease;
}

.logo:hover img {
  transform: scale(1.05);
}

/* Search container */
.search-container {
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: 0 2rem;
  position: relative;
}

.search-container input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.2s;
}

.search-container input:focus {
  outline: none;
  border-color: #0056b3;
}

.search-btn {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #6c757d;
  cursor: pointer;
  display: flex;
  padding: 0.25rem;
}

.search-btn svg {
  width: 20px;
  height: 20px;
}

/* Main navigation */
.main-nav {
  display: flex;
  align-items: center;
}

.main-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
}

.main-nav ul li a {
  color: #212529;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  position: relative;
  transition: color 0.2s;
}

.main-nav ul li a:hover {
  color: #0056b3;
}

.main-nav ul li a:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #0056b3;
  transition: width 0.2s;
}

.main-nav ul li a:hover:after {
  width: 100%;
}

.nav-actions {
  display: flex;
  gap: 1rem;
  margin-left: 2rem;
}

.cart-btn, .menu-btn {
  background: transparent;
  border: none;
  color: #212529;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s, transform 0.2s;
}

.cart-btn svg, .menu-btn svg {
  width: 24px;
  height: 24px;
}

.cart-btn:hover, .menu-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.1);
}

/* Mobile menu button - only visible on smaller screens */
.menu-btn {
  display: none;
}

/* Main content area */
.main-content {
  margin-top: 88px; /* Same as header height */
  flex: 1;
  width: 100%;
}

/* Media queries for responsive design */
@media (max-width: 1024px) {
  .main-nav ul {
    gap: 1.5rem;
  }
  
  .search-container {
    max-width: 300px;
    margin: 0 1rem;
  }
}

@media (max-width: 768px) {
  .main-header {
    height: 72px;
  }
  
  .main-content {
    margin-top: 72px;
  }
  
  .main-nav ul {
    display: none;
  }
  
  .menu-btn {
    display: flex;
  }
  
  .logo {
    max-width: 140px;
  }
  
  .search-container {
    max-width: 200px;
  }
}

@media (max-width: 576px) {
  .main-header .container {
    padding: 0 1rem;
  }
  
  .search-container {
    display: none;
  }
} 