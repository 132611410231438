@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    /* Primary color palette */
    --color-primary: #0056b3;
    --color-primary-rgb: 0, 86, 179;
    --color-primary-light: #3380cb;
    --color-primary-dark: #004494;
    
    /* Secondary colors */
    --color-secondary: #ff6b00;
    --color-secondary-rgb: 255, 107, 0;
    --color-secondary-light: #ff8c3f;
    --color-secondary-dark: #d65800;
    
    /* Neutral colors */
    --color-dark: #212529;
    --color-dark-light: #495057;
    --color-gray-100: #f8f9fa;
    --color-gray-200: #e9ecef;
    --color-gray-300: #dee2e6;
    --color-gray-400: #ced4da;
    --color-gray-500: #adb5bd;
    --color-gray-600: #6c757d;
    
    /* Status colors */
    --color-success: #28a745;
    --color-danger: #dc3545;
    --color-warning: #ffc107;
    --color-info: #17a2b8;
    
    /* Transition and timing */
    --transition: all 300ms ease;
    
    /* Container width for different screen sizes */
    --container-width-lg: 80%;
    --container-width-md: 90%;
}

/* Add this near the top of your index.css, after the :root variables */
a {
    color: var(--color-dark);
    text-decoration: none;
}

/* This prevents the purple visited state */
a:visited {
    color: var(--color-dark);
}

/* Optional: Add hover state */
a:hover {
    color: var(--color-secondary);
}

.site-link {
    color: var(--color-primary);
}

.site-link:visited {
    color: var(--color-primary);
}

.site-link:hover {
    color: var(--color-secondary);
}

html, body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.7;
    color: var(--color-dark);
    background: white;
    overflow-x: hidden;
    position: relative;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 0;
}

main > *:last-child {
    margin-bottom: 0;
}

.container {
    width: var(--container-width-lg);
    max-width: 1400px;
    margin: 0 auto;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.btn {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    background: var(--color-primary);
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
    border: 1px solid transparent;
}

.btn:hover {
    background: var(--color-primary-light);
    box-shadow: 0 4px 10px rgba(var(--color-primary-rgb), 0.2);
}

.btn-secondary {
    background: var(--color-secondary);
}

.btn-secondary:hover {
    background: var(--color-secondary-light);
    box-shadow: 0 4px 10px rgba(var(--color-secondary-rgb), 0.2);
}

.btn-outline {
    background: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.btn-outline:hover {
    background: var(--color-primary);
    color: white;
}

section {
    padding: 6rem 0;
}

h1, h2, h3, h4, h5 {
    line-height: 1.2;
}

h1 {
    font-size: 2.4rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.3rem;
}

.section__head {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.section__head span {
    background-color: var(--color-gray-500);
    padding: .8rem;
    border-radius: 1rem;
    color: var(--color-secondary);
    font-size: 1.7rem;
    display: flex;
}

.card {
    background: var(--color-gray-500);
    border: 2px solid transparent;
    text-align: center;
    padding: 3rem 2rem;
    /*border-radius: 0 3rem 0 3rem;*/
    border-radius: 3rem;
    transition: var(--transition);
}

.card:hover {
    background-color: transparent;
    border-color: var(--color-gray-400);
    cursor: default;
}

.card span {
    width: 3rem;
    height: 3rem;
    background: var(--color-primary);
    color: var(--color-gray-100);
    font-size: 1.5rem;
    padding: .8rem;
    display: grid;
    place-items: center;
    margin: 0 auto 1.5rem auto;
    border-radius: 1rem;
    transition: var(--transition);
}

.card:hover span {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}

.card small {
    margin-top: 1rem;
    display: block;
    font-weight: 300;
}

.header {
    margin-top: 5rem;
    height: 20rem;
    overflow: hidden;
    border-bottom: 2px solid var(--color-gray-400);
}

.header__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    background: black;
}

.header__container-bg {
    position: absolute;
    width: 100%;
}

.header__container-bg img {
    opacity: 0.5;
}

.header__content {
    position: relative;
    width: 44%;
    margin: 0 auto;
    text-align: center;
    color: var(--color-gray-100);
}

.header__content h2 {
    margin-bottom: 1rem;
}

.header__content p {
    color: rgba(255, 255, 255, 0.7);
}

/* Media Queries (Medium Devices) */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        padding: 4rem 0;
    }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px) {
    h1 {
        font-size: 2rem;
    }
    
    h2 {
        font-size: 1.7rem;
    }
    
    section {
        padding: 3rem 0;
    }
}

/* Added for content positioning below fixed navbar */
@media screen and (max-width: 800px) {
    main {
        margin-top: 0;
    }
}
